<template>
  <main>
    <make-banner height="320" :id="1"></make-banner>
    <recomend-list></recomend-list>
    <module-list></module-list>
  </main>
</template>
<script>
import RecomendList from "../components/RecomendList";
import ModuleList from "../components/ModuleList";
export default {
  name: "",
  props: {},
  components: {
    "recomend-list": RecomendList,
    "module-list": ModuleList,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped></style>
